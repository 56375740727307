//Global styles

body {
  background-color: #25649b;
  color: $white;
}

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.narrow {
  max-width: 650px;
}

.logo {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 20px;
  @include media-breakpoint-down(sm) {
    width: 130px;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
  }
}

// Typography

h1,
h2 {
  span {
    color: $orange;
  }
}

p {
  @include media-breakpoint-down(sm) {
    font-size: 0.85rem;
  }
}

p.lead {
  font-weight: 400;
  font-size: 1.4rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
  }
}

a {
  text-decoration: none;
  outline: none;
}

a:active,
a:focus {
  outline: 0;
  border: none;
}

.btn {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  transition: all 0.5s ease;
  color: $white;
  &:hover {
    transform: scale(1.03);
    color: $white;
  }

  &:active {
    transform: scale(1.03);
    color: $white;
  }

  @include media-breakpoint-down(md) {
    padding: 10px 15px;
  }
}

//Subpages

// GAME STATUS

// GAME STATUS

.game-status {
  padding: 5px;
  //margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;

  .round-time-bar {
    overflow: hidden;
    background-color: $white;
    border-radius: 15px;
  }
  .round-time-bar div {
    height: 20px;
    transition: background-color 1000ms linear;
    background-color: $orange;
    border-radius: 15px;
    @include media-breakpoint-down(md) {
      height: 15px;
    }

    &.red {
      background-color: red;
    }
  }
}

.score-booster {
  position: absolute;
  opacity: 0;
  z-index: 2;
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(220, 20, 60, 0.7);
  border-radius: 100%;
  color: white;
  font-weight: 600;
  font-size: 6rem;
  animation: scaleAnimation 0.5s ease-in-out;
  animation-iteration-count: 1;

  span {
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
  }
}

.player {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.sound {
  border: 2px solid $orange;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  .soundicon {
    path {
      fill: white;
    }
  }
  @include media-breakpoint-down(md) {
    right: 5px;
    top: 5px;
    padding: 5px;
  }
}

// ANIMATIONS

@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

@keyframes floatWithShadow {
  0% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(0px);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(0) rotate(-0.5turn);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(-0.5turn);
  }
}

.confetti {
  position: absolute !important;
  z-index: 3;
  left: 50%;
  bottom: 0;
  width: 500px;
  perspective: 500px;
}

canvas {
  z-index: 20 !important;
}

.end-game {
  position: absolute;
  z-index: 5;
  @include media-breakpoint-down(md) {
    top: 90px;
    left: 50%;
    width: 100%;
    left: 0;
    transform: translateX(-50%);
  }
}

.countdowncontainer {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background: $blue-light2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 3rem;
  }
}

table.customTable {
  border: 4px solid $orange;
  color: #000000;
  margin: 0 auto;
  border-collapse: inherit !important;
  padding: 20px;
}

table.customTable td,
table.customTable th {
  color: $white;
  text-align: left;
  border-width: 0px;
  border-color: #eeeeee;
  border-style: solid;
  padding: 5px;
  padding-right: 20px;
}

.rws-backdrop {
  justify-content: center !important;
}

.rws-close {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rws-container {
  background-color: $blue !important;
  /*   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.rws-header {
  color: white;
  text-align: center;
}
.rws-icon {
  background-color: transparent !important;
}

.rws-icon[aria-label="whatsapp"],
.rws-icon[aria-label="reddit"],
.rws-icon[aria-label="telegram"],
.rws-icon[aria-label="mail"],
.rws-icon[aria-label="vk"] {
  display: none;
}
